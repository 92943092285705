import React from "react"
import tw, { styled } from "twin.macro"
import { graphql, Link, useStaticQuery } from "gatsby"

const Sitemap = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            uri
            id
            parentId
            status
            title
          }
        }
      }
      allWpJob {
        edges {
          node {
            uri
            id
            status
            title
          }
        }
      }
      allWpReferenz {
        edges {
          node {
            uri
            id
            status
            title
          }
        }
      }
      allWpHowTo {
        edges {
          node {
            id
            uri
            status
            title
          }
        }
      }
    }
  `);

  if (!data || !data.allWpPage) {
    return <p>Loading...</p>;
  }

  const pages = data.allWpPage.edges;
  const jobs = data.allWpJob.edges;
  const referenzen = data.allWpReferenz.edges;
  const howTo = data.allWpHowTo.edges;

  const SitemapWrapper = styled.div`
    ${tw`flex flex-col w-full mt-10 md:mt-20 lg:mt-40 pb-c96 px-10 pl-offset sm:pl-0 sm:px-12 lg:px-16 xl:px-24 `}

    ul {
      ${tw`w-full`}
      & li {
        ${tw`py-1`}
        & a {
          ${tw`relative uppercase text-lightGrey font-ubuntu text-16`}

          &::after {
            content: "|->";
            ${tw`absolute font-bold transform translate-x-full -right-2 font-firacode`}
          }
        }
      }
    }
  `
  const SitemapHeader = styled.h1`
    ${tw`mb-4 text-base font-bold text-left uppercase font-ubuntu text-customGrey md:text-2xl`}
  `

  const renderPagesWithChildren = (parentId = null, level = 0) => {
    return pages
      .filter(({ node }) => node.parentId === parentId)
      .map(({ node }) => (
        <li key={node.id} style={{ paddingLeft: `${level * 20}px` }}>
          <Link to={node.uri}>
            {node.title || node.uri}
          </Link>
          <ul>
            {renderPagesWithChildren(node.id, level + 1)}
            {node.title == "Jobs" && (
              <ul
                style={{ paddingLeft: `20px` }}
              >
                {jobs
                  .filter(({ node }) => node.status === "publish")
                  .map(({ node }) => (
                    <li key={node.id}>
                      <Link to={node.uri}>
                        {node.title || node.uri}
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
            {node.title == "Referenzen" && (
              <ul
                style={{ paddingLeft: `20px` }}
              >
                {referenzen
                  .filter(({ node }) => node.status === "publish")
                  .map(({ node }) => (
                    <li key={node.id}>
                      <Link to={node.uri}>
                        {node.title || node.uri}
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
            {node.title == "HowTo" && (
              <ul
                style={{ paddingLeft: `20px` }}
              >
                {howTo
                  .filter(({ node }) => node.status === "publish")
                  .map(({ node }) => (
                    <li key={node.id}>
                      <Link to={node.uri}>
                        {node.title || node.uri}
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </ul>
        </li>
      ));
  };

  return (
    <SitemapWrapper>
      <SitemapHeader>Sitemap</SitemapHeader>

      <ul
        css={tw`flex flex-col w-full`}
      >
        {renderPagesWithChildren()}
      </ul>

    </SitemapWrapper>
  )
}

export default Sitemap